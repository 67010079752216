import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransactionCodeCardComponent } from './card/card.component';
import { TransactionCodeListItemComponent } from './list-item/list-item.component';
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [TransactionCodeListItemComponent, TransactionCodeCardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatePipe, 
    IonicModule, 
  ],
  exports: [TransactionCodeListItemComponent, TransactionCodeCardComponent],
})
export class TransactionCodeModule { }
