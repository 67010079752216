import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateComponent } from './update/update.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    UpdateComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    UpdateComponent,
  ],
})
export class MobileAppModule { }
