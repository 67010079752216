<ion-item button detail="true" detail-icon="caret-forward-outline" lines="inset" routerDirection="root" [routerLink]="route">
  <ion-label>
    <h3>{{ transaction_code.code }}</h3>
    <p>{{ transaction_code.name }}</p>
    <p>{{ transaction_code.creation_date | date }}</p>
  </ion-label>

  <ion-label slot="end">
    <p>{{ transaction_code.status }}</p>
  </ion-label>
</ion-item>
