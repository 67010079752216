import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading!: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController) { }

  async present(message: string = 'Processing...'){
    this.loading = await this.loadingCtrl.create({
      message: message,
      duration: 5000,
    });

    this.loading.present();
  }

  async dismiss(){
    await this.loading?.dismiss();
  }

}
