<ion-content>
  <div class="container">
    <img [src]="staticBaseUrl + '/500/cones.png'" />

    <h2>
      <span>500</span> <br />
      Internal server error
    </h2>
    <p>We are currently trying to fix the problem.</p>
    <p class="info">
      <a class="btn-back" routerDirection="root" routerLink="..">Back to previous page</a>
    </p>
  </div>
</ion-content>
