import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CountryInterface } from '@kiddy-cash/common';
import { AuthService, CountryService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import {maskitoPhoneOptionsGenerator} from '@maskito/phone';
import metadata from 'libphonenumber-js/mobile/metadata';
import { MaskitoOptions, MaskitoElementPredicate } from "@maskito/core";
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent  implements OnInit {

  form: FormGroup = new FormGroup({
    phone: new FormControl(''),
    email: new FormControl('')
  });
  staticBaseUrl: string =  environment.staticBaseUrl;
  selectedCountry: CountryInterface | undefined;
  countries: CountryInterface[] = [];
  @Input() type!: string;
  isCodeSent: boolean = false;
  isCodeVerified: boolean  = false;
  isProcessing: boolean = false;

  readonly maskPredicate: MaskitoElementPredicate = async el => (el as HTMLIonInputElement).getInputElement();
  public maskitoPhoneOptions!: MaskitoOptions;

  constructor(
    private authService: AuthService, 
    private modalCtrl: ModalController,
    private countryService: CountryService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.countryService._$countries.subscribe(
      (countries: CountryInterface[]) => {
        this.countries = countries;
      }
    );

    this.countryService._$selectedCountry.subscribe(
      (country: CountryInterface | undefined) => {
        if (!country) return;
        this.selectedCountry = country;

        const iso: CountryCode = country.iso2 as CountryCode
        this.maskitoPhoneOptions = maskitoPhoneOptionsGenerator({
          strict: true,
          countryIsoCode: iso, 
          metadata,
        });
      }
    );
    this.initForm()
  }

  initForm(){
    this.form = this.formBuilder.group({
      phone: [undefined],
      email: ['', [Validators.email, Validators.required]],
      code: ['', Validators.required],
    })
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }

  submitForm() {

  }

  countrySelectionChanged(country: CountryInterface) {
    this.countryService._$selectedCountry.next(country);
  }

  onInputChange(){
    if(this.type === 'phone') {
      this.isProcessing = false;
      this.isCodeSent = false;
      this.isCodeVerified = false;

      this.form.get('code')?.reset();
    } else {
      this.isProcessing = false;
      this.isCodeSent = false;
      this.isCodeVerified = false;

      this.form.get('code')?.reset();
    }
  }

  sendCode(){
    if(this.type === 'phone') {
      const phone =  this.form.value.phone;
      if(this.form.get('phone')?.valid !== true) return;

      const parsedPhoneNumber = parsePhoneNumber(phone)
      const phoneData = parsedPhoneNumber?.number as string;

      this.isProcessing = true;
      this.authService.requestAccountPhoneOTP(phoneData).subscribe((res: any) => {
        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = false;
        if(res.data?.code) this.form.patchValue({code: res.data.code});
      },
      (err: any) => {
        this.isProcessing = false;
      })
    }

    if(this.type === 'email') {
      const email =  this.form.value.email;
      if(this.form.get('email')?.valid !== true) return;

      this.isProcessing = true;
      this.authService.requestAccountEmailOTP(email).subscribe((res: any) => {
        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = false;  
        if(res.data?.code) this.form.patchValue({code: res.data.code});
      },
      (err: any) => {
        this.isProcessing = false;
      })
    }
  }

  verifyCode(){
    if(this.form.get('code')?.valid !== true) return;

    const code =  this.form.value.code;

    if(this.type === 'phone') {
      const phone =  this.form.value.phone;
      if(this.form.get('phone')?.valid !== true) return;

      const parsedPhoneNumber = parsePhoneNumber(phone)
      const phoneData = parsedPhoneNumber?.number as string;

      this.isProcessing = true;
      this.authService.verifyAccountPhoneOTP(phoneData, code).subscribe(async (res: any) => {
        await this.setToken(res.data.token);
        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = true;

        this.modalCtrl.dismiss(this.type, 'confirm');
      },
      (err: any) => {
        this.isProcessing = false;
      })

    }

    if(this.type === 'email') {
      const email =  this.form.value.email;
      if(this.form.get('email')?.valid !== true) return;

      this.isProcessing = true;
      this.authService.verifyAccountEmailOTP(email, code).subscribe(async (res: any) => {
        await this.setToken(res.data.token);
        this.isProcessing = false;
        this.isCodeSent = true;
        this.isCodeVerified = true;

        this.modalCtrl.dismiss(this.type, 'confirm');
      },
      (err: any) => {
        this.isProcessing = false;
      })
    }
  }
  
  setToken(token: string) {
    return this.authService.decodeAndStoreToken(token);
  }

}
