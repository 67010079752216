import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransactionInterface, TransactionStatusEnum } from '@kiddy-cash/common';

@Component({
  selector: 'kc-transaction-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class TransactionCardComponent  implements OnInit {
  @Input() transaction!: TransactionInterface;

  TransactionStatusEnum = TransactionStatusEnum;
  TransactionStatusEnumValues = Object.values(TransactionStatusEnum);

  constructor() { }

  ngOnInit() {}
}
