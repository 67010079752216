import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent  implements OnInit {
  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor() { }

  ngOnInit() {}

}
