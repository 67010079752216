import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient) { }

  getNotification(notification_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/notifications/${notification_id}`,);
  }

  getNotifications() {
    return this.http.get<any>(`${environment.apiBaseUrl}/notifications`);
  }

  registerDevice(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/devices`, data);
  }

  getUnreadNotifications() {
    return this.http.get<any>(`${environment.apiBaseUrl}/notifications/unread`);
  }

  resetUnreadNotifications() {
    return this.http.post<any>(`${environment.apiBaseUrl}/notifications/reset`, undefined);
  }

  deleteDevices(data: any) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/devices`, data);
  }

}
