import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'integration-dtb-astra-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
})
export class DtbAstraSetupComponent  implements OnInit {
  @Input() country_integration: any;
  staticBaseUrl: string =  environment.staticBaseUrl;

  @Output() createIntegrationSubmitForm = new EventEmitter<any>();

  createIntegrationForm: FormGroup = new FormGroup({

  });

  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createIntegrationForm = this.formBuilder.group({

    })
  }

  submitForm() {
    if (!this.createIntegrationForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.createIntegrationForm.value);
      this.createIntegrationSubmitForm.emit(requestData);
    }
  }

}
