import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import Cropper from 'cropperjs';
import { BusinessUserInterface, CountryInterface, ProfileGenderIdentityEnum } from '@kiddy-cash/common';
import { AuthService, LoadingService, CountryService, ToastService, ProfileService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent  implements OnInit {
  @ViewChild('fileInput') input!: ElementRef<HTMLInputElement>;
  @ViewChild('cropperContainer') div!: ElementRef<HTMLDivElement>;
  user: BusinessUserInterface | null | undefined;
  cropper: Cropper | undefined;
  isLoggedIn: boolean = false;
  staticBaseUrl: string =  environment.staticBaseUrl;

  userCountry: CountryInterface | undefined;
  countries: CountryInterface[] = [];
  selectedCountry: CountryInterface | undefined;

  ProfileGenderIdentityEnumValues = Object.values(ProfileGenderIdentityEnum);

  profileUpdateForm: FormGroup = new FormGroup({
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    bio: new FormControl(''),
    dob: new FormControl(''),
    genderidentity: new FormControl(''),
  });

  isSubmitted: boolean  = false;
  isProcessing: boolean = false;
  maxDate:any = new Date();

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    public formBuilder: FormBuilder,
    public countryService: CountryService,
    private modalCtrl: ModalController,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.authService._$user.subscribe((user: BusinessUserInterface | null | undefined) => {
      this.user = user;
      if(user?.profile?.country) this.userCountry = user.profile.country;
      this.initForm();
    });

    this.countryService._$countries.subscribe((countries: CountryInterface[]) => {
      this.countries = countries;
    });

    this.countryService._$selectedCountry.subscribe((country: CountryInterface | undefined) => {
      if(!country) return;
      if(!this.userCountry) this.userCountry = country
    });

    this.maxDate = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd');
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }

  initForm(){
    this.profileUpdateForm = this.formBuilder.group({
      firstname: [this.user?.profile?.firstname ?? '', 
        [
          Validators.required, 
          Validators.minLength(2), 
          Validators.maxLength(30),
        ]
      ],
      lastname: [this.user?.profile?.lastname ?? '', 
        [
          Validators.required, 
          Validators.minLength(2), 
          Validators.maxLength(30),
        ]
      ],
      dob: [this.user?.profile?.dob?.toString() ?? '',
        [
          Validators.required,
        ]
      ],
      genderidentity: [this.user?.profile?.genderidentity ?? '',
        [
          Validators.required,
        ]
      ],
      bio: [this.user?.profile?.bio ?? '', 
        [
          Validators.minLength(2),
          Validators.maxLength(400),
        ]
      ],
    })
  }

  ngAfterViewInit() {
    const component = this;
    const inputElement = this.input?.nativeElement;
    const cropperContainerElement = this.div?.nativeElement;

      inputElement.addEventListener('change', ()=>{
        if(inputElement.files === null || inputElement.files.length === 0) {
          this.destroyCropper(cropperContainerElement);
          return;
        }
  
        const imageFile = inputElement.files[0]
  
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = function (event: ProgressEvent<FileReader>) {
          if(event.target === null || !reader.result) return;
  
          const image: HTMLImageElement = new Image();
          image.src = reader.result.toString();
          image.onload = function () {
            if(component.cropper) {
              // if existing cropper, then let's replace the image
              component.replaceCropperImage(image)
            } else {
              // if no cropper exists, then let's initialize one
              component.initializeCropper(image, cropperContainerElement);
            }
          };
        }
      });
  }

  countrySelectionChanged(country: CountryInterface) {
    this.selectedCountry = country;
    this.userCountry = country;
  }

  initializeCropper(image: HTMLImageElement, cropperContainerElement: HTMLDivElement){
    this.cropper = new Cropper(image, {
      container: cropperContainerElement,
    });
    this.cropper.getCropperSelection()!.initialAspectRatio = 1;
    this.cropper.getCropperSelection()!.aspectRatio = 1;
    this.cropper.getCropperCanvas()!.style.minHeight = "400px";
    this.cropper.getCropperSelection()!.$render();
    this.cropper.getCropperSelection()!.$center();
  }

  replaceCropperImage(image: HTMLImageElement){
    this.cropper!.getCropperImage()!.$image.src = image.src;
  }

  destroyCropper(cropperContainerElement : HTMLDivElement){
    this.cropper = undefined;
    cropperContainerElement.replaceChildren();
  }

  onReset(): void {
    this.isSubmitted = false;
    this.profileUpdateForm.reset();
  }

  async submitForm() {
    this.isSubmitted = true;
    if (!this.profileUpdateForm.valid) {
      return;
    } else {
      this.isProcessing = true;
      this.loadingService.present();

      const requestData = structuredClone(this.profileUpdateForm.value);
      if(this.cropper){
        const canvas = await this.cropper.getCropperSelection()!.$toCanvas({
          width: 400,
          height: 400,
        });
        const avatar = canvas.toDataURL("image/jpeg", 0.8);
        requestData.avatar = avatar;
      }

      if(!requestData.firstname) delete requestData.firstname;
      if(!requestData.lastname) delete requestData.lastname;
      if(!requestData.bio) delete requestData.bio;

      requestData.country_id = this.userCountry?.id

      this.updateProfile(requestData);
    }
  }

  async updateProfile(requestData: any) {
    this.profileService.putProfile(requestData).subscribe((res) => {
      const token = res.token;
      this.authService.decodeAndStoreToken(token);

      this.isProcessing = false;
      this.loadingService.dismiss();
      this.modalCtrl.dismiss(null, 'confirm');
      this.toastService.presentToast('Profile Updated Successfully', 'success');
    },
    (err: any) => {
      this.isProcessing = false;
      this.loadingService.dismiss();
      this.toastService.presentToast(err.error.message, 'danger');
    })
  }
}
