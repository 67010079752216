import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BusinessService, LoadingService, ToastService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class KybGuard implements CanActivate {
  constructor(
    private businessService: BusinessService,
    private loadingService: LoadingService,
    private router: Router, 
    private toastService: ToastService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      this.loadingService.present();
      const business_id = route.paramMap.get('business_id') as string;
      return new Promise((resolve) => {
        this.businessService.getKyb(business_id).subscribe((res) => {
          this.loadingService.dismiss();
          const kyb = res.data.kyb;

          if(!kyb?.passed) {
            // Disallow the route
            resolve(false);

            this.toastService.presentToast('Please complete the KYB step first', 'medium');

            // then navigate to business KYB
            this.router.navigate(['/business', business_id, 'know-your-business']);
          } else {
            // Allow the route
            resolve(true);
          }
        },
        (err: any) => {
          // Disallow the route
          resolve(false);

          this.loadingService.dismiss()
          this.toastService.presentToast(err.error.message, 'danger');
        })
      });
  }
  
}
