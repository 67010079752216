import { Component, Input, OnInit } from '@angular/core';
import { TransactionCodeInterface } from '@kiddy-cash/common';

@Component({
  selector: 'kc-transaction-code-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class TransactionCodeListItemComponent  implements OnInit {
  @Input() transaction_code!: TransactionCodeInterface;
  @Input() route: (string | undefined)[] = [];

  constructor() { }

  ngOnInit() {}

}
