<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-align-items-center ion-justify-content-center min-height-95-vh">
      <ion-col size-xl="6" size-lg="6" size-sm="6" size="12">

        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-thumbnail>
                <img alt="thumbnail" src="{{ staticBaseUrl + '/favicon.png' }}" />
              </ion-thumbnail>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-text>
                <h2>An Update is Available!</h2>
              </ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size="auto">
              <ion-text>
                We are constantly enhancing our feature set, performance and security of our application, an update is available 
                on {{ deviceInfo.platform === 'ios' ? 'the App Store' : 'Google Play'}}. Please tap the button below and you'll be 
                redirected there to update.
              </ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <ion-col size-lg="4" size="6">
              <ion-button expand="block" (click)="openAppStore()">Update</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>