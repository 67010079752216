import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DtbAstraModule } from './dtb-astra/dtb-astra.module';

@NgModule({
  declarations: [],
  exports: [
    DtbAstraModule,
  ],
  imports: [
    CommonModule,
    DtbAstraModule,
  ]
})
export class IntegrationModule { }
