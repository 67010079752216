import { Component, Input, OnInit } from '@angular/core';
import { TransactionCodeInterface } from '@kiddy-cash/common';

@Component({
  selector: 'kc-transaction-code-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class TransactionCodeCardComponent  implements OnInit {
  @Input() transaction_code!: TransactionCodeInterface;

  constructor() { }

  ngOnInit() {}

}
