import { Injectable } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CapacitorService {
  public _$appInfo = new BehaviorSubject<AppInfo | undefined>(undefined);
  public _$deviceInfo = new BehaviorSubject<DeviceInfo | undefined>(undefined);

  constructor() { }

  public async setUp() {
    const deviceInfo: DeviceInfo = await Device.getInfo();
    this._$deviceInfo.next(deviceInfo);

    if(deviceInfo?.platform === 'ios' || deviceInfo?.platform === 'android') {
      const appInfo: AppInfo = await App.getInfo();
      this._$appInfo.next(appInfo);
    }
  }

}
