import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from '../components/breadcrumb/breadcrumb.module';
import { HeaderModule } from '../components/header/header.module';
import { ErrorModule } from '../components/error/error.module';
import { MobileAppModule } from '../components/mobile-app/mobile-app.module';
import { ProfileFormModule } from '../components/profile-form/profile-form.module';
import { RegistrationFormModule } from '../components/registration-form/registration-form.module';
import { AccountModule } from '../components/account/account.module';
import { ActivityModule } from '../components/activity/activity.module';
import { BusinessModule } from '../components/business/business.module';
import { IntegrationModule } from '../components/integration/integration.module';
import { TransactionModule } from '../components/transaction/transaction.module';
import { TransactionCodeModule } from '../components/transaction-code/transaction-code.module';
import { PipesModule } from '../core/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [
    BreadcrumbModule,
    HeaderModule,
    ErrorModule,
    MobileAppModule,
    RouterModule,
    ProfileFormModule,
    RegistrationFormModule,
    AccountModule,
    ActivityModule,
    BusinessModule,
    TransactionModule,
    TransactionCodeModule,
    IntegrationModule,
    PipesModule,
  ],
  providers: [DatePipe],
})
export class SharedModule { }
