<ion-header class="ion-no-border">
  <ion-toolbar>
    <div class="exit-div"><ion-icon name="arrow-back" class="back-icon" (click)="cancel()"></ion-icon>
      <ion-title>Add <span *ngIf="type==='email'">Email</span><span *ngIf="type==='phone'">Phone</span></ion-title>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" >
  <ion-grid >
    <ion-row class="ion-align-items-center ion-justify-content-center" >
      <ion-col size-xl="12" size-lg="6" size-sm="6" size="12" >
        <form [formGroup]="form">
          <ion-item class="input-number" *ngIf="type==='phone'">
            <ion-list class="ion-no-padding country-selector">
              <ion-item #selectCountryTrigger lines="none" class="transparent-background" [button]="true" [detail]="false" id="select-country">
                <ion-img *ngIf="selectedCountry" src="{{ staticBaseUrl + '/flags/' + selectedCountry.flag }}" class="country-flag"></ion-img>
                <ion-text *ngIf="!selectedCountry" class="ion-padding-horizontal">Select Country</ion-text>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </ion-item>
            </ion-list>

            <ion-item class="phone" lines="none">
              <ion-input 
                (ionChange)="onInputChange()" 
                [clearInput]="true" 
                placeholder="Phone number" 
                value="" 
                class="phone-input" 
                
                formControlName="phone" 
                type="tel" 
                inputmode="numeric" 
                [maskito]="maskitoPhoneOptions"
                [maskitoElement]="maskPredicate"
              ></ion-input> 
            </ion-item>     
          </ion-item>
        

          <ion-item class="phone" *ngIf="type==='email'">
            <ion-label position="floating">Email</ion-label>
            <ion-input 
              (ionChange)="onInputChange()" 
              [clearInput]="true" 
              placeholder="Email" 
              value="" 
              class="phone-input" 
              
              formControlName="email" 
              type="email" 
              inputmode="email"
            ></ion-input> 
          </ion-item>
    

        <ng-container *ngIf="!isCodeSent && !isCodeVerified">
          <ion-button *ngIf="!isProcessing" shape="round" (click)="sendCode()" class="send-code-btn" [disabled]="(type==='phone' && !form.controls['phone'].valid) || (type==='email' && !form.controls['email'].valid)">Send Code</ion-button>
    
          <ion-item *ngIf="isProcessing" class="send-code-btn transparent-background">
            <ion-label>Processing</ion-label>
            <ion-spinner name="dots"></ion-spinner>
          </ion-item>
        </ng-container>

        <ng-container *ngIf="isCodeSent && !isCodeVerified">
          <ion-item class="send-code-btn code-item">
            <ion-input labelPlacement="floating" label="Code" placeholder="Code"  formControlName="code" type="tel" inputmode="numeric" minlength="4" maxlength="6" pattern="[0-9]+"></ion-input>
          </ion-item>
    
          <ion-button *ngIf="!isProcessing" shape="round" (click)="verifyCode()" class="send-code-btn" >Verify Code</ion-button>
    
          <ion-item *ngIf="isProcessing"  class="send-code-btn transparent-background">
            <ion-label>Processing</ion-label>
            <ion-spinner name="dots"></ion-spinner>
          </ion-item>
        </ng-container>
      </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-modal trigger="select-country" #modal *ngIf="countries.length">
  <ng-template>
    <kc-select-country
      class="ion-page"
      title="Select Your Country"
      [countries]="countries"
      [selectedCountry]="selectedCountry"
      (selectionChange)="countrySelectionChanged($event); modal.dismiss()"
      (selectionCancel)="modal.dismiss()"
    ></kc-select-country>
  </ng-template>
</ion-modal>
