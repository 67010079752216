import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) { }

  putProfile(data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/profile`, data);
  }

  getKyc() {
    return this.http.get<any>(`${environment.apiBaseUrl}/profile/know-your-customer`);
  }

  uploadKyc(data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/profile/know-your-customer`, data);
  }

}
