import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { CountryInterface } from '@kiddy-cash/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  public _$countryCode: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  public _$ipAddress: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  public _$countries = new BehaviorSubject<CountryInterface[]>([]);
  public _$selectedCountry = new BehaviorSubject<CountryInterface | undefined>(undefined);

  constructor(private http: HttpClient) { }

  combinedTimers = combineLatest([this._$countryCode, this._$countries])
    .subscribe(([countryCode, countries]) => {
      const selectedCountry = countries.find((country: CountryInterface) => country.iso2 === countryCode);
      if(selectedCountry) this._$selectedCountry.next(selectedCountry);
    })

  getCountries() {
    return this.http.get<any>(`${environment.dataBaseUrl}/countries`);
  }

  fetchCountries(){
    this.getCountries().subscribe((response) => {
      this._$countries.next(response.data.countries)
    })
  }

  getCloudflareTrace() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    return this.http.get<string>(`${environment.baseUrl}/cdn-cgi/trace`, requestOptions);
  }

  processCloudFlareResponse(response: string){
    const service = this;

    const trace: any[] = []; 
    const lines: any[] = response.split('\n');

    lines.forEach(function(line: string){
      const keyValue: any[] = line.split('=');
      const key = keyValue[0];
      const value = keyValue[1];

      trace[key] = decodeURIComponent(value || '');

      if('loc' === key && 'XX' !== value){
        service._$countryCode.next(value);
      }

      if('ip' === key){
        service._$ipAddress.next(value);
      }
    });
  }

  traceLocation(){
    this.getCloudflareTrace().subscribe((textResponse) => {
      this.processCloudFlareResponse(textResponse)
    })
  }

}
