import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  constructor(private http: HttpClient) { }

  // GET Requests
  getAllBusinesses() {
    return this.http.get<any>(`${environment.apiBaseUrl}/business`);
  }

  getBusinessesIds() {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/ids`);
  }

  getBusinessesAccounts() {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/account`);
  }

  getBusinessesTransactions() {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/transaction`);
  }

  getBusiness(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}`);
  }

  getBusinessAccounts(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/account`);
  }

  getBusinessTransactions(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/transaction`);
  }

  getBusinessTransactionCodes(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/transaction-code`);
  }

  getCountryIntegrationForBusiness(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/country_integration`);
  }

  getKyb(business_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/know-your-business`);
  }

  getBusinessAccount(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/account/${account_id}`);
  }

  getBusinessAccountTransactions(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/account/${account_id}/transaction`);
  }

  getActiveBusinessAccountIntegration(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/account/${account_id}/integration`);
  }

  getCountryIntegrationForBusinessAccount(business_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/account/${account_id}/country_integration`);
  }

  getBusinessAccountTransaction(business_id: string, account_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/account/${account_id}/transaction/${transaction_id}`);
  }

  getBusinessTransaction(business_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/transaction/${transaction_id}`);
  }

  getBusinessTransactionCode(business_id: string, transaction_code_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/business/${business_id}/transaction-code/${transaction_code_id}`);
  }


  // POST Requests
  createBusiness(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/business`, data);
  }

  createBusinessAccount(business_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/business/${business_id}/account`, data);
  }

  createBusinessTransactionCode(business_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/business/${business_id}/transaction-code`, data);
  }

  createBusinessAccountIntegration(business_id: string, account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/business/${business_id}/account/${account_id}/integration`, data);
  }

  withdrawFromBusinessAccount(business_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/business/${business_id}/withdraw`, data);
  }

  transactionForBusinessAccount(business_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/business/${business_id}/transaction`, data);
  }

  addBusinessManagers(business_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/business/${business_id}/managers`, data);
  }

  //PUT Requests
  updateBusiness(business_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/business/${business_id}`, data);
  }

  updateBusinessAccount(business_id: string, business_account_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/business/${business_id}/account/${business_account_id}`, data);
  }

  uploadKyb(business_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/business/${business_id}/know-your-business`, data);
  }

  updateBusinessTransactionCode(business_id: string, transaction_code_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/business/${business_id}/transaction-code/${transaction_code_id}`, data);
  }

  // Delete Requests
  closeBusinessAccount(business_id: string, account_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/business/${business_id}/account/${account_id}`);
  }

  removeBusinessManagers(business_id: string, data: any) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/business/${business_id}/managers`, {
      body: data
    });
  }

}
