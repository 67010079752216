import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransactionCardComponent } from './card/card.component';
import { TransactionListItemComponent } from './list-item/list-item.component';
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [TransactionListItemComponent, TransactionCardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DatePipe, 
    IonicModule, 
  ],
  exports: [TransactionListItemComponent, TransactionCardComponent],
})
export class TransactionModule { }
