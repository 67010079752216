import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'integration-dtb-astra-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss'],
})
export class DtbAstraWithdrawComponent  implements OnInit {
  @Input() account_integration: any;
  staticBaseUrl: string =  environment.staticBaseUrl;

  @Output() withdrawIntegrationSubmitForm = new EventEmitter<any>();

  withdrawIntegrationForm: FormGroup = new FormGroup({

  });

  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.withdrawIntegrationForm = this.formBuilder.group({

    })
  }

  submitForm() {
    if (!this.withdrawIntegrationForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.withdrawIntegrationForm.value);
      this.withdrawIntegrationSubmitForm.emit(requestData);
    }
  }
}
