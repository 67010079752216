import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toast!: HTMLIonToastElement;
  private toastDismissButton = [
    {
      text: 'Dismiss',
      role: 'cancel'
    }
  ];

  constructor(private toastController: ToastController) { }

  async presentToast(
    message: string, 
    color: "danger" | "dark" | "light" | "medium" | "primary" | "secondary" | "success" | "tertiary" | "warning" | undefined = undefined,
    showDismissButton: boolean = false, 
    duration: number = 2500, 
    position: 'top' | 'middle' | 'bottom' = 'bottom'
  ) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position
    });

    if(showDismissButton) toast.buttons = this.toastDismissButton;
    if(color) toast.color = color;

    this.toast = toast;
    await this.toast.present();
  }

  async dismiss() {
    await this.toast?.dismiss();
  }
}
