import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CountryInterface } from '@kiddy-cash/common';
import { ToastService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-select-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent  implements OnInit {
  @Input() countries: CountryInterface[] = [];
  @Input() selectedCountry: CountryInterface | undefined;
  @Input() title = 'Select Country';

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<CountryInterface>();
  
  staticBaseUrl: string =  environment.staticBaseUrl;

  filteredCountries: CountryInterface[] = [];
  workingSelectedCountry: CountryInterface | undefined;

  constructor(private toastService: ToastService) { }

  ngOnInit() {
    this.filteredCountries = [...this.countries];
  }
  
  trackCountries(index: number, country: CountryInterface) {
    return country.id;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }
  
  confirmChanges() {
    if(!this.workingSelectedCountry){
      this.toastService.presentToast('Please select a country first', 'warning')
    } else {
      this.selectionChange.emit(this.workingSelectedCountry);
    }
  }
  
  searchbarInput(ev: any) {
    this.filterList(ev.target.value);
  }
  
  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredCountries = [...this.countries];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase(); 
      this.filteredCountries = this.countries.filter(country => {
        return country.name.toLowerCase().includes(normalizedQuery) || country.phone_code.includes(normalizedQuery);
      });
    }
  }

  radioChange(ev: any) {
    const country_id = ev.detail.value;

    const selectedCountry = this.countries.find((country: CountryInterface) => country.id === country_id);
    if(selectedCountry) this.workingSelectedCountry = selectedCountry;
  }

}
