<ion-item button detail="true" detail-icon="caret-forward-outline" lines="inset" routerDirection="root" [routerLink]="route">
  <ion-icon name="pause-circle-outline" color="primary" slot="start" *ngIf="transaction.status === 'Pending'"></ion-icon>
  <ion-icon name="checkmark-circle-outline" color="success" slot="start" *ngIf="transaction.status === 'Approved'"></ion-icon>
  <ion-icon name="thumbs-down-outline" color="warning" slot="start" *ngIf="transaction.status === 'Declined'"></ion-icon>
  <ion-icon name="stop-circle-outline" color="danger" slot="start" *ngIf="transaction.status === 'Cancelled'"></ion-icon>
  <ion-icon name="hand-right-outline" color="danger" slot="start" *ngIf="transaction.status === 'Flagged'"></ion-icon>

  <ion-label>
    <h3>{{ transaction.status }}</h3>
    <p>{{ transaction.to_account.name }}</p>
    <p>{{ transaction.creation_date | date }}</p>
  </ion-label>

  <ion-label slot="end">
    <p>{{ transaction.from_account.country.currency.code }} {{ transaction.amount | number }}</p>
  </ion-label>
</ion-item>
