import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'integration-dtb-astra-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss'],
})
export class DtbAstraDepositComponent  implements OnInit {
  @Input() account_integration: any;
  staticBaseUrl: string =  environment.staticBaseUrl;

  @Output() depositIntegrationSubmitForm = new EventEmitter<any>();

  mpesaSTKPushForm: FormGroup = new FormGroup({
    phonenumber: new FormControl(),
    amount: new FormControl(),
  });

  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.mpesaSTKPushForm = this.formBuilder.group({
      phonenumber: ['',
        [
          Validators.required,
        ]
      ],
      amount: [0,
        [
          Validators.min(1),
          Validators.required,
        ]
      ],
    })
  }

  submitMpesaSTKPushForm() {
    if (!this.mpesaSTKPushForm.valid) {
      return;
    } else {
      const requestData = structuredClone(this.mpesaSTKPushForm.value);
      requestData.type = 'KE_DTB_STK_PUSH';
      this.emitOutput(requestData);
    }
  }

  emitOutput(data: any) {
    this.depositIntegrationSubmitForm.emit(data);
  }
}
