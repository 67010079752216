import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoadingService, ProfileService, ToastService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class KycGuard implements CanActivate {
  constructor(
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private router: Router, 
    private toastService: ToastService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      this.loadingService.present();
      return new Promise((resolve) => {
        this.profileService.getKyc().subscribe((res) => {
          this.loadingService.dismiss();
          const kyc = res.data.kyc;

          if(!kyc?.passed) {
            // Disallow the route
            resolve(false);

            this.toastService.presentToast('Please complete the KYC step first', 'medium');

            // then navigate to dashboard
            this.router.navigate(['/profile', 'know-your-customer']);
          } else {
            // Allow the route
            resolve(true);
          }
        },
        (err: any) => {
          // Disallow the route
          resolve(false);

          this.loadingService.dismiss()
          this.toastService.presentToast(err.error.message, 'danger');
        })
      });
  }
  
}
