import { Component, Input, OnInit } from '@angular/core';
import { BreadCrumbInterface } from '@kiddy-cash/common';

@Component({
  selector: 'kc-breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent  implements OnInit {
  @Input() breadcrumbs: BreadCrumbInterface[] = [];

  constructor() { }

  ngOnInit() {}

}
