import { Routes } from '@angular/router';
import { AuthGuard, KybGuard, NoAuthGuard } from './core/guards';

export const routes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('./pages/home/home.page').then( m => m.HomePage)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/dashboard/dashboard.page').then( m => m.DashboardPage)
  },
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'register',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('./pages/register/register.page').then( m => m.RegisterPage)
  },
  {
    path: 'reset-password',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('./pages/reset-password/reset-password.page').then( m => m.ResetPasswordPage)
  },
  {
    path: 'about',
    loadComponent: () => import('./pages/about/about.page').then( m => m.AboutPage)
  },
  {
    path: 'terms',
    loadComponent: () => import('./pages/terms/terms.page').then( m => m.TermsPage)
  },
  {
    path: 'privacy',
    loadComponent: () => import('./pages/privacy/privacy.page').then( m => m.PrivacyPage)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/profile/profile.page').then( m => m.ProfilePage)
  },
  {
    path: 'profile/know-your-customer',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/profile/know-your-customer/know-your-customer.page').then( m => m.KnowYourCustomerPage)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/notifications/notifications.page').then( m => m.NotificationsPage)
  },
  {
    path: 'business',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/business.page').then( m => m.BusinessPage)
  },
  {
    path: 'business/create',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/create/create.page').then( m => m.CreatePage)
  },
  {
    path: 'business/account',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/accounts/accounts.page').then( m => m.AccountsPage)
  },
  {
    path: 'business/transaction',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/transactions/transactions.page').then( m => m.TransactionsPage)
  },
  {
    path: 'business/:business_id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/view/view.page').then( m => m.ViewPage)
  },
  {
    path: 'business/:business_id/know-your-business',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/know-your-business/know-your-business.page').then( m => m.KnowYourBusinessPage)
  },
  {
    path: 'business/:business_id/managers',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/managers/managers.page').then( m => m.ManagersPage)
  },
  {
    path: 'business/:business_id/withdraw',
    canActivate: [AuthGuard, KybGuard],
    loadComponent: () => import('./pages/business/withdraw/withdraw.page').then( m => m.WithdrawPage)
  },
  {
    path: 'business/:business_id/transaction',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/transaction/transaction.page').then( m => m.TransactionPage)
  },
  {
    path: 'business/:business_id/transaction/create',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/transaction/create/create.page').then( m => m.CreatePage)
  },
  {
    path: 'business/:business_id/account',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/account/account.page').then( m => m.AccountPage)
  },
  {
    path: 'business/:business_id/account/create',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/account/create/create.page').then( m => m.CreatePage)
  },
  {
    path: 'business/:business_id/account/:business_account_id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/account/view/view.page').then( m => m.ViewPage)
  },
  {
    path: 'business/:business_id/account/:business_account_id/integration',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/account/integration/integration.page').then( m => m.IntegrationPage)
  },
  {
    path: 'business/:business_id/account/:business_account_id/deposit',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/account/deposit/deposit.page').then( m => m.DepositPage)
  },
  {
    path: 'business/:business_id/account/:business_account_id/withdraw',
    canActivate: [AuthGuard, KybGuard],
    loadComponent: () => import('./pages/business/account/withdraw/withdraw.page').then( m => m.WithdrawPage)
  },
  {
    path: 'business/:business_id/account/:business_account_id/transaction',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/account/transaction/transaction.page').then( m => m.TransactionPage)
  },
  {
    path: 'business/:business_id/account/:business_account_id/transaction/create',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/account/transaction/create/create.page').then( m => m.CreatePage)
  },
  {
    path: 'business/:business_id/account/:business_account_id/transaction/:transaction_id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/business/account/transaction/view/view.page').then( m => m.ViewPage)
  },
  {
    path: 'business/:business_id/transaction-code',
    loadComponent: () => import('./pages/business/transaction-code/transaction-code.page').then( m => m.TransactionCodePage)
  },
  {
    path: 'business/:business_id/transaction-code/create',
    loadComponent: () => import('./pages/business/transaction-code/create/create.page').then( m => m.CreatePage)
  },
  {
    path: 'business/:business_id/transaction-code/:transaction_code_id',
    loadComponent: () => import('./pages/business/transaction-code/view/view.page').then( m => m.ViewPage)
  },
  {
    path: '**',
    loadComponent: () => import('./pages/not-found/not-found.page').then( m => m.NotFoundPage)
  },
];
