// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyDXq6FCLEKkTuLmYjSm2VgkQpPnC6wnJLg",
    authDomain: "dev-kiddy-cash.firebaseapp.com",
    projectId: "dev-kiddy-cash",
    storageBucket: "dev-kiddy-cash.appspot.com",
    messagingSenderId: "558421973028",
    appId: "1:558421973028:web:4a9e8b3ffab39b1c7e60b1",
    measurementId: "G-ELE72NR39B",
    vapidKey: "BFyJXkHyWEDjVNZ2NgtB6aXiWVxYHEpkIuAC4pkaTmpjEJSzS3_40qAgL275wezjmJOoKg6XQSG_dBjsIMPGrIc",
  },
  baseUrl: 'https://business-dev.kiddy.cash',
  staticBaseUrl: 'https://dev-static.kiddy.cash',
  apiBaseUrl: 'https://dev-api.kiddy.cash/api',
  authBaseUrl: 'https://dev-api.kiddy.cash/auth',
  dataBaseUrl: 'https://dev-api.kiddy.cash/data',
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
