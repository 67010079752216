import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryComponent } from './country/country.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [CountryComponent],
  exports: [CountryComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
})
export class SelectModule { }
