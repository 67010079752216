import { APP_INITIALIZER, enableProdMode, EnvironmentProviders, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './app/core/interceptors/auth.interceptor';
import { AuthService, CountryService, CapacitorService } from './app/services';
import { provideHttpClient } from '@angular/common/http';
import { FirebaseApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics,getAnalytics,ScreenTrackingService } from '@angular/fire/analytics';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Preferences } from '@capacitor/preferences';
import { AppEnum } from '@kiddy-cash/common';

let firebaseApp: FirebaseApp;

const serviceWorkerProviders: EnvironmentProviders = importProvidersFrom([
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: !isDevMode(),
    // Register the ServiceWorker as soon as the application is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000',
  }),
]);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy,
    },
    provideHttpClient(),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    provideFirebaseApp(() => {
      firebaseApp = initializeApp(environment.firebase)
  
      return firebaseApp
    }),
    provideAnalytics(() => getAnalytics(firebaseApp)),
    provideMessaging(() => getMessaging(firebaseApp)),
    providePerformance(() => getPerformance(firebaseApp)),
    serviceWorkerProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ScreenTrackingService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [
        AuthService, 
        CountryService, 
        CapacitorService,
      ],
      useFactory: (
        authService: AuthService,
        countryService: CountryService,
        capacitorService: CapacitorService,
      ) => async () => {
        await Preferences.configure({
          group: `${environment.firebase.projectId}.${AppEnum.Business}`
        })
        await authService.checkStoredUser()
        countryService.fetchCountries()
        countryService.traceLocation()
        await capacitorService.setUp()
      },
    },
    importProvidersFrom(IonicModule.forRoot({animated: true})),
    provideRouter(routes),
  ],
});
